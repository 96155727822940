<template>
    <b-alert :show="intCountdown" dismissible :variant="variant" @dismissed="$emit('input', 0)" class="success-message">
        {{ message }}
    </b-alert>
</template>

<script>
    export default {
        props: {
            value: {},
            message: {
                type: String,
                default: 'Сохранено'
            },
            variant: {
                type: String,
                default: 'success'
            }
        },
        watch: {
            value() {
                this.intCountdown = this.value
            }
        },
        data() {
            return {
                intCountdown: this.value
            }
        }
    }
</script>

<style scoped>
    .success-message {
        margin-top: 10px;
        margin-bottom: 0;
    }

    @media (min-width: 768px) {
        .success-message {}
    }

    @media (max-width: 768px) {
        .success-message {
            position: fixed;
            bottom: 50px;
        }
    }
</style>