<template>
    <select v-model="currentValue" class="form-control" @change="onChange" :disabled="disabled">
        <option :value="currentValue" disabled v-if="currentValue && !options.find(lot => lot.id === currentValue.id)">
            {{ optionFormatter(currentValue) }}
        </option>
        <option v-for="option in options" v-bind:key="option.id" :value="option">
            {{ optionFormatter(option) }}
        </option>
    </select>
</template>

<script>
    export default {
        props: {
            disabled: {
                type: Boolean,
                default: false
            },
            availableItems: {
                type: Array,
                default: []
            },
            value: {},
            nomenclatureId: {},
            optionFormatter: {
                type: Function,
                default(option) {
                  return this.defaultOptionFormatter(option)
                }
            }
        },
        watch: {
            value() {
                this.setValue()
            },
            options() {
                this.setValue()
            }
        },
        computed: {
            options() {
                return this.availableItems.filter(lot => lot.nomenclature.id === this.nomenclatureId)
            }
        },
        data() {
            return {
                currentValue: null
            }
        },
        methods: {
            onChange() {
                this.$emit('input', this.currentValue)
            },
            setValue() {
                if (this.value) {
                    let item = this.options.find(lot => lot.id === this.value.id)
                    this.currentValue = item ? item : this.value
                } else {
                    this.currentValue = null
                }
            },
            getCountString(value) {
                let count = '';
                if (!!value.count) {
                    count = value.count;

                    if (!!value.nomenclature && !!value.nomenclature.measureUnit) {
                        count += ' ' + value.nomenclature.measureUnit.name;
                    }

                    count = '(' + count + ')';
                }

                return count;
            },
            defaultOptionFormatter(option) {
                return option.name + ' ' + this.getCountString(option)
            }
        },
        mounted() {
            this.setValue()
        }
    }
</script>
