<template>
    <div class="text-left" id="material-transfer-act-nomenclature-list">
        <b-card-group style="display: block;">
            <b-card no-body v-for="item in items" :key="item.id" :bg-variant="item._rowVariant">
                <div class="card-body" style="padding: 0 .4rem;">
                    <div style="float: right;" @click="rowClicked(item)">
                        <span style="line-height: 24px;" :class="isItemValid(item) ? 'valid-item' : 'not-valid-item'">{{ lotSum(item) }} {{ item.nomenclature.measureUnit.name }}</span>
                    </div>

                    <div class="pull-right" style="margin-right: 10px;" v-if="getMaxCount(item) !== null">
                        <span style="line-height: 24px;" :class="isItemValid(item) ? 'valid-item' : 'not-valid-item'">{{getMaxCountString(item)}}</span>
                    </div>

                    <h6 style="margin-bottom: 0; line-height: 24px;" @click="rowClicked(item)">
                        {{ item.nomenclature.name }}
                    </h6>

                    <b-collapse :id="item.id + ''" accordion="items" v-model="item.collapsed">
                        <b-form-group v-if="!!item.count" description="Необходимое Кол-во" style="padding-top: 20px;">
                            <div class="input-group">
                                <input type="number" v-model="item.count" :disabled="disabled" class="form-control">
                                <div class="input-group-append">
                                    <span class="input-group-text" :class="{'alert-success': requiredCollected(item), 'alert-danger': !requiredCollected(item)}">Набрано: {{lotSum(item)}}</span>
                                </div>
                            </div>
                        </b-form-group>

                        <div v-for="(lot, lot_index) in item.lots" class="form-group">
                            <div class="row lot-group">
                                <div class="col-md-5">
                                    <b-form-group description="Партия">
                                        <b-input-group>
                                            <nomenclature-lot-pick :disabled="disabled"
                                                                   :available-items="filterSelectedLots(lots, item, lot)"
                                                                   v-model="item.lots[lot_index]['nomenclatureLot']"
                                                                   @input="setDefaultLotCount(item, lot_index); $forceUpdate()"
                                                                   :nomenclature-id="item.nomenclature.id"
                                            ></nomenclature-lot-pick>
                                        </b-input-group>
                                    </b-form-group>
                                </div>
                                <div class="col-md-4">
                                    <b-form-group description="Кол-во">
                                        <div class="input-group">
                                            <div v-if="!!item.lots[lot_index]['nomenclatureLot']" class="input-group-prepend">
                                                <span class="input-group-text" :class="{'alert-danger' : !lotCountValid(lot), 'alert-success': lotCountValid(lot)}">max: {{getMaxCount(item.lots[lot_index])}}</span>
                                            </div>
                                            <input type="number" @input="$forceUpdate()" v-model="item.lots[lot_index]['count']" :disabled="disabled" class="form-control">
                                            <div class="input-group-append">
                                                <span class="input-group-text">{{item.nomenclature.measureUnit.name}}</span>
                                            </div>
                                        </div>
                                    </b-form-group>
                                </div>
                                <div class="col-md-2">
                                    <b-form-group description="Ящик" style="margin-bottom: 0;">
                                        <input v-model="lot.box.name" @focus="selectedLot = lot" @blur="selectedLot = null" :disabled="disabled" class="form-control">
                                    </b-form-group>
                                </div>
                                <div class="col-md-1">
                                    <b-form-group class="text-right">
                                        <button :disabled="disabled" @click="item.lots.splice(lot_index, 1); $forceUpdate();" class="btn btn-sm btn-outline-danger"><i class="fas fa-times"></i></button>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                        <div class="form-group" v-if="filterSelectedLots(lots, item).length > 0">
                            <div class="row">
                                <div class="col-md-12 text-center">
                                    <button @click="(!!item.lots ? null : item.lots = []); item.lots.push({box:{name:null}}); $forceUpdate();" class="btn btn-sm btn-primary" :disabled="disabled"><i class="fas fa-plus"></i> добавить партию</button>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="!disabled">
                            <b-col cols="12" style="text-align: right">
<!--                                <b-button variant="info" @click="$emit('copy', item)">📋</b-button>-->
                                <button class="btn btn-sm btn-outline-danger" @click="$emit('remove', item)" >
                                <i class="fas fa-times text-lg-center"></i>
                                    Удалить номенклатуру
                                </button>
                            </b-col>
                        </div>
                    </b-collapse>
                </div>
            </b-card>
        </b-card-group>
    </div>
</template>

<script>
    import NomenclatureLotPick from '../_common/NomenclatureLotPick'
    import NomenclatureListBase from '../_common/NomenclatureListBase'
    import { BOX_OBJECT_TYPE, NOMENCLATURE_LOT_OBJECT_TYPE, NOMENCLATURE_OBJECT_TYPE } from "../../utils/objectTypes";
    import {LotCountMixin} from '@mixins';
    import BarcodeRepository from "../../utils/BarcodeRepository";

    export default {
        extends: NomenclatureListBase,
        components: {
            NomenclatureLotPick
        },
        mixins: [LotCountMixin],
        props: {
            items: {
                required: true,
                type: Array
            },
            storage: {
                type: Object,
                default: null
            },
            lots: {
                required: true,
                type: Array,
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            nomenclatureIds() {
                return this.items.map(i => i.nomenclature.id)
            }
        },
        data() {
            return {
                selectedLot: null,
            }
        },
        methods: {
            addItem(item) {
                this.$emit('addItem', item);
            },
            isItemValid(item) {

                if (!Array.isArray(item.lots) || !item.lots.length) {
                    return false;
                }

                let invalidLots = item.lots.filter(lot => {
                    return ( !(!!lot.nomenclatureLot) ) || ( !this.storage.allowLotBelowZero && !this.lotCountValid(lot) );
                });

                return invalidLots.length === 0;
            },

            requiredCollected(item) {
                return this.lotSum(item) >= parseFloat(item.count);
            },
            filterSelectedLots(lots, item, excludedItemLot) {
                if (!Array.isArray(item.lots)) {
                    return lots;
                }

                let selected = item.lots.map(lot => {

                    if (excludedItemLot && excludedItemLot.nomenclatureLot && lot.nomenclatureLot
                        && lot.nomenclatureLot.id === excludedItemLot.nomenclatureLot.id
                    ) {
                        return null;
                    }

                    return lot.nomenclatureLot ? lot.nomenclatureLot.id : null;
                });

                if (!selected.length) {
                    return lots;
                }

                return lots.filter(lot => {
                    return selected.indexOf(lot.id) === -1 && lot.nomenclature.id === item.nomenclature.id;
                })
            },
            getMaxCountString(item) {

                let count = this.getMaxCount(item);

                if (count !== null && !!item.nomenclatureLot.nomenclature && !!item.nomenclatureLot.nomenclature.measureUnit) {
                    count += ' ' + item.nomenclatureLot.nomenclature.measureUnit.name;
                }

                return '(max: ' + (count ? count : '') + ')';
            },
            onBarcodeScanned(data) {

                if (!this.storage) {
                    alert('Выберите передающий склад');
                }

                if (this.act && this.act.received) return;

                BarcodeRepository.findFirstResourceByBarcode(data.barcode)
                    .then(result => {
                        if (!result) {
                            for (let i = 0; i < BarcodeRepository.nomenclatureLotPrefixes.length; i++) {
                                if (data.barcode.startsWith(BarcodeRepository.nomenclatureLotPrefixes[i])) {
                                    alert(`Партия не найдена!\nШК: ${data.barcode}`);
                                    return
                                }
                            }

                            if (window.confirm('Нет ШК! Создать?')) {
                                this.$router.push({name: 'BarcodeCreate', query: {barcode: data.barcode}})
                            }

                            return
                        }

                        let item = {};

                        switch (result.object.objectType) {
                            case NOMENCLATURE_OBJECT_TYPE:
                                this.addItem({
                                    nomenclature: result.resource,
                                    count: 0,
                                    lots: [],
                                });
                                break;

                            case NOMENCLATURE_LOT_OBJECT_TYPE:
                                item = {
                                    nomenclature: result.resource.nomenclature,
                                    nomenclatureLot: result.resource,
                                    count: null,
                                    lots: [],
                                };

                                let lot = this.lots.filter(l => {
                                    return l.id === result.resource.id
                                });

                                if (lot.length) {

                                    item.lots.push({
                                        count: 0,
                                        box: {name: null},
                                        exists: false,
                                        nomenclatureLot: lot.length ? lot[0] : null,
                                    });

                                    this.addItem(item);
                                }


                                break;

                            case BOX_OBJECT_TYPE:
                                if (this.selectedLot) {

                                    this.$set(this.selectedLot, 'box', result.resource)
                                } else {
                                    this.alertModal = true;
                                    this.alertBarcode = data.barcode;

                                    setTimeout(() => {
                                        this.alertModal = false;
                                        this.alertBarcode = null
                                    }, 8000)
                                }
                                break;
                        }
                    })
            },
        },
        mounted() {
            this.$root.listenToBarcodeScanning(this);
        }
    }
</script>

<style>
    #material-transfer-act-nomenclature-list input {
        font-size: small !important;
    }

    #material-transfer-act-nomenclature-list .card.bg-secondary {
        color: white !important;
    }

    #material-transfer-act-nomenclature-list .card.bg-secondary .text-muted {
        color: white !important;
    }

    #material-transfer-act-nomenclature-list .card-group > .card {
        border-radius: 0 !important;
        margin-bottom: 0 !important;
    }

    #material-transfer-act-nomenclature-list .valid-item {
        background: #28a745;
        border-radius: 5px;
        padding: 0 2px;
        color: white;
    }

    #material-transfer-act-nomenclature-list .not-valid-item {
        background: rgba(236, 46, 64, 0.91);
        border-radius: 5px;
        padding: 0 2px;
        color: white;
    }

    #material-transfer-act-nomenclature-list .lot-group {
        border-bottom: 1px dashed #e4e5e6;
        padding-top: 1rem;
    }
</style>
