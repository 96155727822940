<template>
    <b-container fluid="">
        <error-list :errors="errors" :message-function="errorMessage" @clear="errors = []"></error-list>

        <b-card no-body>
            <b-tabs pills card>
                <b-tab title="Склады" active>
                    <b-row>
                        <b-col>
                            <b-form-group description="Передал" v-if="!!act && !!act.access">
                                <storage-picker
                                    v-model="act.storageFrom"
                                    :allowed-roles="act.access.can_edit ? true : null"
                                    :disabled="disabled"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group description="Принял">
                                <storage-picker v-model="act.storageTo" :allowed-roles="null" :disabled="disabled"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="productionDateAndTargetActualShiftRequired">
                        <b-col md="6">
                            <b-form-group description="Дата отгрузки">
                                <b-form-datepicker
                                    v-model="act.production_plan_date"
                                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                    :start-weekday="1"
                                    locale="ru"
                                    placeholder="Выберите дату отгрузки"
                                    :disabled="disabled"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <actual-shift-picker description="Принимающая смена" :disabled="disabled"
                                                 :allowEmptyShift="true" v-model="act.targetActualShift"/>
                        </b-col>
                    </b-row>
                    <b-row v-if="act.id">
                        <b-col cols="12" class="text-center">
                            <b-alert show :variant="act.received ? 'success' : 'danger'">
                                {{ act.received ? 'ПРИНЯТО' : 'НЕ ПРИНЯТО' }}
                            </b-alert>
                        </b-col>
                    </b-row>
                    <b-row v-if="act.productionAct && act.productionAct.id">
                        <b-col class="text-center">
                            <b-link
                                :to="{name: 'ProductionActUpdate', params: {id: act.productionAct.id}}"
                            >
                                По выпуску № {{ act.productionAct.id }}
                            </b-link>
                        </b-col>
                    </b-row>
                    <b-row v-if="act.qualityControlAct">
                        <b-col class="text-center">
                            <b-button
                                variant="link"
                                @click="toQualityControlAct"
                            >
                                По акту контроля качества № {{ act.qualityControlAct.id }}
                            </b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group description="Комментарий">
                                <b-form-textarea v-model="act.comment" :disabled="disabled"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-tab>
                <b-tab title="Что передаем">
                    <nomenclature-list
                        ref="nomenclatureList"
                        :storage="act.storageFrom"
                        :items="act.items"
                        :lots="lots"
                        :disabled="disabled"
                        @remove="removeItem"
                        @addItem="addItem"
                        @selectLot="selectedLot = $event"
                        @unSelectLot="selectedLot = null"
                    />
                    <find-component :addItem="addItem" v-show="!disabled"/>
                </b-tab>
            </b-tabs>
        </b-card>

        <success-message v-model="successCountDown"/>

        <control-panel v-show="act">
            <b-button variant="danger" @click="$router.push({'name': 'MaterialTransferActList'})">Выйти</b-button>
            <b-dropdown v-if="act && act.id" variant="warning">
                <template #button-content>
                    <b-icon-printer-fill/>
                </template>
                <b-dropdown-item @click="openPrintQueueModal">Печать</b-dropdown-item>
                <b-dropdown-item v-if="act.storageTo.id === SALE_STORAGE_ID" @click="getSaleWaybill">Печать накладной ПМ
                    (PDF)
                </b-dropdown-item>
            </b-dropdown>
            <div v-if="transferIsBlocked" class="text-center text-danger">
                НА ЭТУ ДАТУ УЖЕ ВСЕ ОТГРУЖЕНО
            </div>
            <b-button variant="success" @click="save" :disabled="transferIsBlocked || isLoading">
                <template v-if="!isLoading">
                    Передать
                </template>
                <template v-else>
                    <b-spinner small/>
                    Передача в
                </template>
                <span v-if="act !== null && act.storageTo !== null">{{ act.storageTo.name }}</span>
            </b-button>
        </control-panel>

        <b-modal v-model="alertModal" :ok-only="true" :hide-header="true" centered>
            <h4>
                Выбери партию.
                <br>Емкость {{ alertBarcode }}
            </h4>
        </b-modal>

        <users-of-the-act :act="act"/>
    </b-container>
</template>

<script>
import NomenclatureList                                   from './NomenclatureList';
import ActualShiftPicker                                  from '@components/_common/ActualShiftPicker';
import StoragePicker                                      from '@components/_common/StoragePicker';
import FindComponent                                      from './FindComponent';
import BarcodeRepository                                  from '@utils/BarcodeRepository.js';
import ErrorList                                          from '@components/_common/ErrorList';
import ControlPanel                                       from '@components/_common/ControlPanel';
import SuccessMessage                                     from '@components/_common/SuccessMessage';
import {findIndex, forEach, uniq, keys, keyBy, cloneDeep} from 'lodash';
import {
    MATERIAL_TRANSFER_ACT_ENDPOINT,
    PRODUCTION_PLANS_ENDPOINT,
    NOMENCLATURE_LOTS_ENDPOINT,
    QUALITY_CONTROL_ACT_ENDPOINT,
    CORRECTION_ACTS_ENDPOINT,
    NOMENCLATURES_ENDPOINT,
}                                                         from '@utils/endpoints';
import UsersOfTheAct                                      from '@components/_common/UsersOfTheAct';
import moment                                             from 'moment';
import LotCountMixin                                      from '@mixins/LotCountMixin';
import MaterialTransferPrintModal                         from './MaterialTransferPrintModal';
import {ROLE_STOCKMAN, ROLE_STORAGE_SENIOR}               from '@utils/Roles';
import Downloader                                         from '@utils/Downloader';
import ErrorsBag                                          from '@utils/errorsBag';

const STAFF_CANTEEN_STORAGE_ID = 21
const RECEIVING_STORAGE_ID = 22;
const SALE_STORAGE_ID = 35;
const RETURN_STORAGE_ID = 36;

export default {
    components: {
        FindComponent,
        NomenclatureList,
        ErrorList,
        ActualShiftPicker,
        StoragePicker,
        ControlPanel,
        SuccessMessage,
        UsersOfTheAct,
        MaterialTransferPrintModal
    },
    mixins: [LotCountMixin],
    computed: {
        currentActId() {
            return this.$route.params.id ? this.$route.params.id : null
        },
        disabled() {
            return this.act && !this.act.access.can_edit
        },
        selectedItem() {
            return this.act.items.find(i => i._rowVariant === 'secondary')
        },
        productionDateAndTargetActualShiftRequired() {
            return this.act && this.act.storageFrom && this.act.storageFrom.material && this.act.storageTo && !this.act.storageTo.material
                && !this.act.storageTo.brand_kitchen && this.act.storageTo.id !== STAFF_CANTEEN_STORAGE_ID
        },
        transferIsBlocked() {
            if (this.act && this.act.production_plan_date && this.lastShippedDate) {
                return moment(this.act.production_plan_date) <= moment(this.lastShippedDate)
            }
            return false
        }
    },
    watch: {
        currentActId(newVal, oldVal) {
            if (oldVal) {
                this.resetData()
            }
        },
        availableStorages(newVal) {
            if (newVal.length) {
                // Обновляем стораджи т.к. они хранились в localStorage и могут не иметь новых полей
                let tmp = null;
                if (!!this.act && !!this.act.storageFrom) {
                    tmp = newVal.filter(s => s.id === this.act.storageFrom.id)[0];
                    if (!!tmp) {
                        this.act.storageFrom = tmp;
                    }
                }

                tmp = null;
                if (!!this.act && !!this.act.storageTo) {
                    tmp = newVal.filter(s => s.id === this.act.storageTo.id)[0];
                    if (!!tmp) {
                        this.act.storageTo = tmp;
                    }
                }
            }
        },
        selectedLot(val) {
            if (val) {
                const event = new Event('barcodeScanned');
                event.data = {barcode: 'QS21426'};
                document.dispatchEvent(event);
            }
        },
        'act.storageFrom': function () {
            if (!this.productionDateAndTargetActualShiftRequired) {
                this.act.production_plan_date = null;
                this.act.targetActualShift = null;
            }

        },
        'act.storageTo': function () {
            if (!this.productionDateAndTargetActualShiftRequired) {
                this.act.production_plan_date = null;
                this.act.targetActualShift = null;
            }
        }
    },
    data() {
        return {
            act: {
                id: null,
                storageFrom: {},
                storageTo: {},
                access: {},
                items: [],
            },
            errors: [],
            successCountDown: 0,
            alertModal: false,
            alertBarcode: null,
            transferPlace: null,
            with: [
                'storageFrom',
                'storageTo',
                'author',
                'receivedBy',
                'productionAct',
                'qualityControlAct',
                'correctionAct',
                'items.nomenclatureLot.nomenclature.measureUnit',
                'items.box',
                'targetActualShift.shift'
            ],
            selectedLot: null,
            lots: [],
            nullableLots: [],
            goBackStep: -1,
            goBackRoute: null,
            lastShippedDate: null,
            SALE_STORAGE_ID,
            isLoading: false,
        }
    },
    methods: {
        moment,
        getBaseUrl() {
            return MATERIAL_TRANSFER_ACT_ENDPOINT
        },
        resetData() {
            if (this.currentActId) {
                this.fetchAct();
            } else {
                let filtersJson = localStorage.getItem('MaterialTransferActList-filter'),
                    storageFrom = null,
                    storageTo = null;

                if (filtersJson) {
                    filtersJson = JSON.parse(filtersJson);
                    storageFrom = filtersJson.storage ? filtersJson.storage : null;
                    storageTo = filtersJson.storageTo ? filtersJson.storageTo : null;
                }

                this.act = {
                    storageFrom,
                    storageTo,
                    comment: '',
                    items: [],
                    access: {
                        can_edit: true,
                    },
                    production_plan_date: null,
                    targetActualShift: null
                };

                let params = this.$route.params;

                if (params.transfer) {
                    this.initTransfer(params.transfer);
                }

                if (params.goBackRoute) {
                    this.goBackRoute = params.goBackRoute;
                }
            }
        },
        initTransfer(params) {
            switch (params.place) {
                case QUALITY_CONTROL_ACT_ENDPOINT:
                    this.act.qualityControlAct = {id: params.placeId};
                    break;

                case CORRECTION_ACTS_ENDPOINT:
                    this.act.correctionAct = {id: params.placeId};
                    break;
            }

            if (params.storage) {
                this.act.storageFrom = params.storage;
            }

            if (params.storageFrom) {
                this.act.storageFrom = params.storageFrom;
            }

            if (params.storageTo) {
                this.act.storageTo = params.storageTo;
            }

            if (params.productionPlanDate) {
                this.act.production_plan_date = moment(params.productionPlanDate).toDate();
            }

            if (!!params.date && !!params.shift) {
                this.act.targetActualShift = {
                    date: params.date,
                    shift: params.shift
                };
            }

            if (params.productionAct) {
                this.act.productionAct = params.productionAct;
            }

            if (!!params.batchByProductionActId) {
                this.act.batchByProductionActId = true;
            }

            if (params.transmissionItems && params.transmissionItems.length > 0) {
                this.initItems(params.transmissionItems);
            }

            this.$forceUpdate();
        },
        initItems(items) {
            let aggregatedByLots = {};
            let nomenclatureWithoutLots = [];

            forEach(items, function (item) {
                if (item.nomenclatureLot) {
                    aggregatedByLots[item.nomenclatureLot.id.toString()] = item;
                    return;
                }

                nomenclatureWithoutLots.push(item);
            });

            let nomenclatureLotsIds = keys(aggregatedByLots);
            let nomenclatureWithoutLotsIds = uniq(nomenclatureWithoutLots.map(l => l.nomenclature.id));

            if (nomenclatureLotsIds.length) {
                this.$http
                    .get(`${NOMENCLATURE_LOTS_ENDPOINT}/index`, {
                        params: {
                            ids: nomenclatureLotsIds,
                            storageIds: [this.act.storageFrom.id],
                            with: ['nomenclature.measureUnit'],
                        }
                    })
                    .then(response => {
                        response.data.data.data.forEach((item) => {
                            let count = aggregatedByLots[item.id.toString()].count;
                            let box = {name: null};

                            if (!!aggregatedByLots[item.id.toString()].box) {
                                box = aggregatedByLots[item.id.toString()].box;
                            }

                            let newItem = {
                                nomenclature: item.nomenclature,
                                count: parseFloat(count),
                                lots: [
                                    {
                                        box: box,
                                        count: 0,
                                        nomenclatureLot: item
                                    },
                                ]
                            };
                            newItem.lots[0].count = this.getDefaultLotCount(newItem, 0);

                            this.addItem(newItem);
                        });
                    });
            }

            if (nomenclatureWithoutLotsIds.length) {
                this.$http
                    .get(NOMENCLATURES_ENDPOINT, {params: {ids: nomenclatureWithoutLotsIds, with: ['measureUnit']}})
                    .then(response => {
                        const nomenclaturesHash = keyBy(response.data.data.data, 'id');
                        nomenclatureWithoutLots.forEach(item => {
                            this.addItem({
                                nomenclature: nomenclaturesHash[item.nomenclature.id],
                                count: item.count,
                                lots: [],
                            })
                        });
                    })
            }
        },
        fetchAct() {
            this.$http.get(this.getBaseUrl() + '/' + this.currentActId, {
                params: {
                    with: this.with
                }
            }).then(response => {
                this.setActFromServerResponse(response.body);
            });
        },
        setActFromServerResponse(data) {
            // Hack for format saving
            this.act = data;

            this.act.items = this.groupItems(data.items);
        },
        groupItems(data) {

            let items = [];

            data.map(item => {

                let index = findIndex(items, i => {
                    return i.nomenclature.id === (
                        !!item.nomenclature ? item.nomenclature.id : item.nomenclatureLot.nomenclature.id
                    )
                });

                if (index === -1) {
                    items.push({
                        exists: true,
                        id: Math.ceil(Math.random() * 10000000),
                        nomenclature: !!item.nomenclature ? item.nomenclature : item.nomenclatureLot.nomenclature,
                        lots: []
                    });
                    index = items.length - 1;
                }

                if (!!item.nomenclatureLot) {
                    items[index].lots.push({
                        id: item.id,
                        exists: !!item.exists ? item.exists : true,
                        count: parseFloat(item.count),
                        box: !!item.box ? item.box : {name: null},
                        nomenclatureLot: item.nomenclatureLot
                    })
                }

            });

            return items;
        },
        save() {
            this.errors = [];

            if (!this.act.storageFrom) {
                this.errors.push('Поле передал обязательно для заполнения');
            }

            if (!this.act.storageTo) {
                this.errors.push('Поле принял обязательно для заполнения');
            }

            if (this.act.storageTo.id === RETURN_STORAGE_ID && !this.act.qualityControlAct) {
                this.errors.push('Акт контроля качества обязателен при передаче в ' + this.act.storageTo.name);
            }

            if (this.act.storageTo.material === false
                && this.act.storageFrom
                && this.act.storageFrom.id === RECEIVING_STORAGE_ID
            ) {
                this.errors.push('Установлен запрет на передачу с \'Приемка скл.\' в производственные цеха');
            }

            let method = this.currentActId ? 'put' : 'post',
                url = this.getBaseUrl() + (
                    this.currentActId ? `/${this.currentActId}` : ''
                ),
                data = this.getDataForSave();

            if (new Set(data?.items.map(item => item.box.name)).size !== data?.items.length) {
                this.errors.push('В акте дублируются ящики');
            }

            if (this.errors.length) {
                return;
            }

            data.with = this.with;

            this.isLoading = true;
            this.$http[method](url, data)
                .then(response => {
                    if (!!this.act.batchByProductionActId) {
                        this.goBack();
                    } else {
                        this.successCountDown = 5;
                        this.setActFromServerResponse(response.body);

                        if (this.act.productionAct) {
                            this.goBack();
                        } else {
                            if (!this.currentActId) {
                                this.goBackStep--;
                                this.$router.push({
                                    name: 'MaterialTransferActUpdate', params: {
                                        id: response.body.id,
                                        goBackRoute: this.goBackRoute
                                    }
                                })
                            }
                        }
                    }
                })
                .catch(error => {
                    if (error.body) {
                        if (error.body.errors) {
                            Object.keys(error.body.errors).map(key => {
                                if (Array.isArray(error.body.errors[key])) {
                                    error.body.errors[key].map(message => {
                                        this.errors.push(message);
                                    })
                                }
                            });
                        } else if (error.body.message) {
                            this.errors = [error.body.message]
                        } else {
                            this.errors = [error.status + ' Произошла ошибка']
                        }
                    } else {
                        this.errors = [error.status + ' Произошла ошибка']
                    }
                }).finally(() => this.isLoading = false);
        },
        getDataForSave() {
            let data = {};

            if (this.act.id) {
                data.id = this.act.id;
            }

            if (this.act.storageFrom) {
                data.storage_from_id = this.act.storageFrom.id;
            }

            if (this.act.storageTo) {
                data.storage_to_id = this.act.storageTo.id;
            }

            data.production_plan_date = this.act.production_plan_date
                ? moment(this.act.production_plan_date).format('YYYY-MM-DD')
                : null;

            if (this.act.qualityControlAct) {
                data.quality_control_act_id = this.act.qualityControlAct.id;
            }

            if (this.act.correctionAct) {
                data.correction_act_id = this.act.correctionAct.id;
            }

            if (this.act.productionAct) {
                data.production_act_id = this.act.productionAct.id;
            }

            if (!!this.act.batchByProductionActId) {
                data.batch_by_production_act_id = 1;
            }

            data.comment = this.act.comment;

            data.targetShift = this.act.targetActualShift && this.act.targetActualShift.shift ? {
                date: moment(this.act.targetActualShift.date).format('YYYY-MM-DD'),
                id: this.act.targetActualShift.shift.id
            } : null;

            data.items = [];

            this.act.items.map(item => {

                item.lots.map(lot => {

                    let boxName =
                        BarcodeRepository.boxPrefixes[1] +
                        Math.floor(Math.random() * (
                            99999 - 10000 + 1
                        ) + 10000).toString();

                    data.items.push({
                        id: lot.exists ? lot.id : null,
                        count: parseFloat(lot.count),
                        box: !!item.box && !!item.box.name ? item.box : {name: boxName},
                        nomenclature_lot_id: lot.nomenclatureLot ? lot.nomenclatureLot.id : null,
                    });

                });

            });

            return data;
        },
        addItem(item, forceNew = false) {
            this.fetchLots(item.nomenclature.id);

            let index = findIndex(this.act.items, i => {
                return i.nomenclature.id === item.nomenclature.id
            });

            if (index === -1 || forceNew) {
                item.id = Math.ceil(Math.random() * 10000000);

                this.act.items.push(item);

                this.$refs.nomenclatureList.selectItem(item, true)
            } else {
                if (!!item.count) {
                    this.act.items[index].count = (
                        parseFloat(item.count) + parseFloat(this.act.items[index].count || 0)
                    ).toFixed(3);
                }

                if (item.lots && item.lots.length) {
                    if (!this.act.items[index].lots) {
                        this.act.items[index].lots = [];
                    }

                    item.lots.map(lot => {

                        let existsIndex = findIndex(this.act.items[index].lots, i => {
                            if (!!i.nomenclatureLot) {
                                return i.nomenclatureLot.id === lot.nomenclatureLot.id;
                            }

                            return true;
                        });

                        if (existsIndex === -1) {
                            this.act.items[index].lots.push(lot);
                            this.setDefaultLotCount(this.act.items[index], this.act.items[index].lots.length - 1);
                        } else if (!(
                            !!this.act.items[index].lots[existsIndex].nomenclatureLot
                        )) {
                            this.act.items[index].lots[existsIndex] = lot;
                        }
                    });
                }

                this.$refs.nomenclatureList.selectItem(this.act.items[index], true)
            }
        },
        removeItem(item) {
            this.$bvModal.msgBoxConfirm('Удалить номенклатуру?', {
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Да',
                cancelTitle: 'Нет',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    if (value === true) {
                        const index = this.act.items.findIndex(i => i === item);

                        if (index !== -1) {
                            this.act.items.splice(index, 1);
                        }
                    }
                });
        },
        errorMessage(error) {
            if (typeof error === 'string') {
                return error
            }

            let matchResult = error.propertyPath.match(/^items\[(\d+)]/),
                message = error.message;

            if (matchResult) {
                message = `${this.act.items[matchResult[1]].nomenclature.name}: ${message}`
            }

            return message
        },
        fetchLots(nomenclatureId) {
            if (!!this.act && !!this.act.storageFrom) {
                let params = {
                    without_loading: true,
                    storageIds: [this.act.storageFrom.id],
                };

                if (nomenclatureId) {
                    params.nomenclatureIds = [nomenclatureId];
                }

                if (!!this.act.date) {
                    params.dateTo = this.act.date;
                }

                this.$http
                    .get(NOMENCLATURE_LOTS_ENDPOINT, {
                        params: params
                    })
                    .then(response => {
                        this.lots = response.body;
                        this.syncItemLots();
                    })
            } else {
                this.lots = []
            }
        },
        syncItemLots() {
            this.act.items.map(item => {
                if (!!item.lots && item.lots.length) {
                    item.lots.map((lot, lot_index) => {
                        if (!!lot.nomenclatureLot) {
                            let withCount = this.lots.filter(l => l.id === lot.nomenclatureLot.id);
                            if (withCount.length) {
                                lot.nomenclatureLot = withCount[0];
                                if (!(
                                    !!lot.count
                                )) {
                                    this.setDefaultLotCount(item, lot_index);
                                }
                            }
                        }
                    });
                }
            });
        },
        goBack() {
            if (!!this.goBackRoute) {
                this.$router.push(this.goBackRoute);
            } else {
                this.$router.go(this.goBackStep);
            }
        },

        openPrintQueueModal() {
            let act = cloneDeep(this.act);
            act.items = [];

            forEach(this.act.items, item => {
                if (item.exists !== true) {
                    return true;
                }

                forEach(item.lots, lot => {
                    act.items.push(lot);
                });
            });

            this.$modal.show(MaterialTransferPrintModal, {
                act: act,
                successCallback: () => {
                }
            }, {
                adaptive: true,
                height: 'auto',
                width: 900,
                scrollable: true,
            })
        },

        getSaleWaybill() {
            ErrorsBag.discard();
            this.$http.get(
                `${MATERIAL_TRANSFER_ACT_ENDPOINT}/${this.act.id}/sale-waybill`,
                {
                    responseType: 'arraybuffer',
                }
            )
                .then((response) => {
                    Downloader.download(
                        response.data,
                        'application/pdf',
                        `Накладная ПМ.pdf`
                    );
                })
                .catch((response) => {
                    // Парсим arraybuffer в строку
                    const textDecoder = new TextDecoder('UTF-8');
                    const error = textDecoder.decode(response.data);

                    ErrorsBag.add(error)
                    window.scrollTo({top: 0, behavior: 'smooth'});
                });
        },

        fetchLastShippedDate() {
            this.$http
                .get(PRODUCTION_PLANS_ENDPOINT + '/last-shipped-date')
                .then(response => response.data)
                .then(({lastShippedDate}) => {
                    this.lastShippedDate = lastShippedDate
                })
                .catch(() => {
                    this.$bvToast.toast('Не удалось получить последнюю дату отгрузки', {variant: 'danger'});
                })
        },
        toQualityControlAct() {
            if (this.$auth.user().roles
                && this.$auth.user().roles.some((role) => [ROLE_STOCKMAN, ROLE_STORAGE_SENIOR].includes(role))
            ) {
                this.$router.push({name: 'QualityControlActShow', params: {id: this.act.qualityControlAct.id}});
            } else {
                this.$router.push({name: 'QualityControlActUpdate', params: {id: this.act.qualityControlAct.id}});
            }
        }
    },
    mounted() {
        this.resetData();
        this.fetchLastShippedDate();
    },
}
</script>
