<script>
    export default {
        props: {
            items: {
                type: Array,
                required: true
            },
            value: {}
        },
        methods: {
            rowClicked(item) {
                this.selectItem(item)
            },
            selectItem(item, forceOpen = false) {
                this.items.map(i => this.setUnselected(i))
                this.$emit('input', item)
                this.$set(item, 'collapsed', forceOpen || item.collapsed !== true)
                this.setSelected(item)
            },
            setSelected(item) {
                this.$set(item, '_rowVariant', 'secondary')
            },
            setUnselected(item) {
                this.$set(item, '_rowVariant', '')
            },
            isSelected(item) {
                return item._rowVariant === 'secondary'
            }
        }
    }
</script>