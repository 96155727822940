<template>
    <b-card no-body class="mt-2" style="background: rgb(245, 222, 179)">
        <b-card-body>
            <h5 v-b-toggle.find-nomenclature>
                <b-icon-plus scale="1.5"/>
                Добавить номенклатуру
            </h5>
            <b-collapse id="find-nomenclature">
                <b-row>
                    <b-col md="6" style="z-index: 3">
                        <b-form-group description="Номенклатура/Партия">
                            <multiselect v-model="internalValue"
                                         :options="enhancedOptions"
                                         :multiple="false"
                                         trackBy="id"
                                         :custom-label="labelFunction"
                                         placeholder=""
                                         select-label=""
                                         selected-label=""
                                         deselect-label=""
                                         deselected-label=""
                                         @search-change="onSearch"
                                         :show-no-results="false"
                                         open-direction="bottom"
                            >
                                <template slot="option" slot-scope="item">
                                    <span :style="{'text-decoration': item.option.nomenclature.deactivated ? 'line-through' : ''}">{{labelFunction(item.option)}}</span>
                                </template>
                            </multiselect>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group style="margin-bottom: .5rem;">
                            <b-input-group :prepend="measureUnit">
                                <input v-model="count" type="number" class="form-control">
                                <b-input-group-append>
                                    <b-button :disabled="internalValue === null || !count" variant="success" @click="add">
                                        Добавить
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-collapse>
        </b-card-body>
    </b-card>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    import Multiselect from 'vue-multiselect'
    import lo from 'lodash';
    import NomenclatureInput from "../_common/NomenclatureInput";
    import {NOMENCLATURES_ENDPOINT} from "../../utils/endpoints";

    const WITH = [
        'measureUnit'
    ];

    export default {
        name: "FindComponent",
        components: {NomenclatureInput, Multiselect},
        props: {
            addItem: Function,
        },
        computed: {
            measureUnit() {
                return this.internalValue ? this.internalValue.nomenclature.measureUnit.name : ''
            },
            enhancedOptions () {
                // Уникальность option-a - сочетание id ном-ры и id партии (если она есть)
                return this.options.map(o => ({ ...o, id: `${o.nomenclature.id}-${o.nomenclatureLot ? o.nomenclatureLot.id : ''}` }))
            }
        },
        data() {
            return {
                options: [],
                count: 0,
                internalValue: null,
                lastSearch: null,
                request: null
            }
        },
        methods: {
            labelFunction(item) {
                let label = item.nomenclature.name

                if (item.nomenclatureLot) {
                    label += ` (${item.nomenclatureLot.name})`
                }

                return label
            },
            add() {
                this.addItem({
                    nomenclature: this.internalValue.nomenclature,
                    nomenclatureLot: this.internalValue.nomenclatureLot,
                    count: parseFloat(this.count),
                    box: {name: null}
                })
                this.count = 0
            },
            onSearch(search) {
                if (search === this.lastSearch) {
                    return
                }

                this.lastSearch = search;
                this.options = [];

                if (!search) {
                    return;
                }

                this.search(search, this);
            },
            search: lo.debounce((search, vm) => {
                vm.$http.get(NOMENCLATURES_ENDPOINT, {
                        params: {
                            filters: {
                                search_name: search
                            },
                            sortBy: 'name',
                            sortDirection: 'asc',
                            limit: 25,
                            with: WITH,

                            without_loading: true
                        },
                        before(request) {

                            // abort previous request, if exists
                            if (this.previousRequest) {
                                this.previousRequest.abort();
                            }

                            // set previous request on Vue instance
                            this.previousRequest = request;
                        }
                    })
                    .then(response => {
                        response.data.data.data.forEach(nomenclature => {
                            vm.options.push({
                                nomenclature,
                                nomenclatureLot: null,
                                count: nomenclature.measureUnit.is_weighted ? 0 : 1,
                                box: {name: null}
                            })
                        })
                    })
                    .catch(response => this.$root.responseError(response, 'Ошибка поиска номенклатуры'));
            }, 250),
        }
    }
</script>

<style>
    .multiselect__element {
        z-index: 9999;
    }

    .material-transfer-act-form {
        height: 100%;
    }

    .material-transfer-act-form h6:hover {
        cursor: pointer;
    }
</style>
