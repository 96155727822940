<template>
    <b-form-group :description="description">
        <b-row>
            <b-col cols="6">
                <b-form-datepicker
                    v-model="date"
                    @input="load"
                    :disabled="disabled || !!loading"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    :start-weekday="1"
                    locale="ru"
                    placeholder=""
                />
            </b-col>
            <b-col cols="6">
                <shift-input :disabled="disabled || !!loading" @input="load()" v-model="shift"></shift-input>
            </b-col>
        </b-row>
    </b-form-group>
</template>

<script>
import ShiftInput                  from './ShiftInput';
import {PRODUCTION_SHIFT_ENDPOINT} from '../../utils/endpoints';
import moment                      from 'moment';

export default {
    name: 'ActualShiftPicker',
    components: {
        ShiftInput
    },
    props: {
        value: {},
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        storage: {
            type: Object,
            required: false,
            default: null,
        },
        description: {
            type: String,
            required: false,
            default: 'Выберите смену'
        },
        allowEmptyShift: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    watch: {
        value() {
            this.internalValue = this.value ? this.value : null;
        },
        storage() {
            this.loadRelevant();
        },
        internalValue() {
            if (!this.internalValue) {
                this.date = null;
                this.shift = null;

                return null;
            }

            this.date = moment(this.internalValue.date).format('YYYY-MM-DD');
            this.shift = this.internalValue.shift;
        }
    },
    data() {
        return {
            loading: 0,
            internalValue: null,
            date: null,
            shift: null,
        };
    },

    methods: {
        datePickerFormatter(val) {
            return moment(val).toDate();
        },
        load() {
            if (!!this.date && !!this.shift) {
                this.get();
            } else if (this.allowEmptyShift) {
                if (this.internalValue) {
                    this.internalValue.shift = null;
                    const internalValue = Object.assign({}, this.internalValue);
                    this.$emit('input', internalValue);
                }
            }
        },
        get() {
            this.loading = true;

            let params = {
                date: moment(this.date).format('YYYY-MM-DD'),
                shift_id: this.shift.id,

                without_loading: true,
            };

            this.$http.get(PRODUCTION_SHIFT_ENDPOINT, {
                params: params,
                before(request) {
                    // abort previous request, if exists
                    if (this.previousRequest) {
                        this.previousRequest.abort();
                    }

                    // set previous request on Vue instance
                    this.previousRequest = request;
                }
            }).then(response => {
                this.internalValue = response.data;
                this.$emit('input', this.internalValue);
            })
                .catch(response => this.$root.responseError(response, 'Ошибка получения смены'))
                .finally(() => {
                    this.loading = false;
                });
        },
        loadRelevant() {
            if (!this.storage || !this.storage.id) {
                return;
            }
            if (!!this.value) {
                return;
            }
            this.loading = true;
            this.$http.get(PRODUCTION_SHIFT_ENDPOINT + '/current', {
                params: {
                    storage_id: this.storage.id,
                    without_loading: true,
                },
                before(request) {
                    // abort previous request, if exists
                    if (this.previousRequest) {
                        this.previousRequest.abort();
                    }

                    // set previous request on Vue instance
                    this.previousRequest = request;
                }
            }).then(response => {
                this.internalValue = response.data;
                this.$emit('input', this.internalValue);
            }).catch(response => this.$root.responseError(response, 'Ошибка получения смены'))
                .finally(() => {
                    this.loading = false;
                });
        }
    },
    mounted() {
        this.loadRelevant();
        if (!!this.value) {
            this.internalValue = this.value;
        }
    },
}
</script>
